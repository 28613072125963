import React from 'react';

class Dates extends React.Component {
    render(){
        return (
            <div className="mainContent">
                <div className="textContent">
                    <h2>Event Dates</h2>
                    All dates are subject to change or cancellation - adverse weather may force last minute changes!
                    <br/><br/>
                    Please check before coming. Updates will be sent via email to the mailing list and posted on social media.
                    <br/><br/>
                </div>
                <br/>
                <div className="textContent desktopOnly">
                    <table>
                        <thead>
                            <tr>
                                <th>Day</th>
                                <th>Date</th>
                                <th>Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="past">
                                <td>Sunday</td>
                                <td>12th January 2025</td>
                                <td>10.30am - 12.30pm</td>
                            </tr>
                            <tr className="next">
                                <td>Sunday</td>
                                <td>27th April 2025</td>
                                <td>10.30am - 12.30pm</td>
                            </tr>
                            <tr>
                                <td>Sunday</td>
                                <td>13th July 2025</td>
                                <td>10.30am - 12.30pm</td>
                            </tr>
                            <tr>
                                <td>Sunday</td>
                                <td>12th October 2025</td>
                                <td>10.30am - 12.30pm</td>
                            </tr>
                            <tr>
                                <td>Sunday</td>
                                <td>11th January 2026</td>
                                <td>10.30am - 12.30pm</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className = "mobileOnly">
                    <p className="dateLine past">Sunday 12th January 2025<br/>10-30am - 12.30pm<br/></p>
                    <p className="dateLine next">Sunday 27th April 2025<br/>10-30am - 12.30pm<br/></p>
                    <p className="dateLine">Sunday 13th July 2025<br/>10-30am - 12.30pm<br/></p>
                    <p className="dateLine">Sunday 12th October 2025<br/>10-30am - 12.30pm<br/></p>
                    <p className="dateLine">Sunday 11th January 2026<br/>10-30am - 12.30pm<br/></p>
                </div>
            </div>
        )
    
    }
}

export default Dates;
